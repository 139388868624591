import React from 'react'
import styled from 'styled-components'
import '../styles/spectre.css';
import '../styles/icons/css/icons.css'

// 💅
const FunctionalComponentShipmentItems = styled.div`
.es-c-container--add-items-to-shipment {
  // 🎛
  width: 100%;
  // 🎨
  // 🖋
  font-family: 'Open Sans';
  .wrapper-add-items-to-shipment {
    // 🎛
    margin: 0 auto;
    max-width: 1360px;
    // 🎨
    border-radius: 3px;
    border: solid 1px #bbb;
    // 📰
    display: grid;
    grid-template-rows: 0.3fr 0.7fr 0.2fr 0.1fr 0.2fr;
    grid-template-columns: 1fr;
    grid-template-areas:
    "g_row_1"
    "g_row_2"
    "g_row_3"
    "g_row_4"
    "g_row_5";


    .wrapper-row-1 {
      grid-area: g_row_1; // 🗺
      background-color: #e7edf0;
      padding: 10px;
      // padding: 9px 15px 9px 10px;
      // border: solid 1px #bbb;
      .es-sg-row-1 {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr 0.5fr 0.5fr;
        grid-template-areas:
        "sg_subtitle sg_subtitle sg_subtitle sg_subtitle sg_subtitle sg_subtitle sg_subtitle sg_edit";
        .es-sg-subtitle {
          grid-area: sg_subtitle; // 🗺
          font-size: 18px;
          font-weight: 600;
          color: #2b363b;
        }
        .es-sg-edit {
          grid-area: sg_edit; // 🗺
          justify-self: end;
          color: #666;
    font-weight: 600;
    background-color: transparent;
        }
      }
    }
    .wrapper-row-2 {
      grid-area: g_row_2; // 🗺
      background-color: #e7edf0;
      padding: 10px;
      .es-sg-row-2 {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr 0.5fr 0.5fr;
        grid-template-areas:
        "sg_freight_class sg_freight_class sg_nmfc sg_nmfc sg_nmfc sg_nmfc sg_hazardous sg_hazardous";
        .es-sg-freight-class {
          grid-area: sg_freight_class; // 🗺
          font-weight: 400;
          font-size: 18px;
          span {
            font-size: 12px;
            color: #666;
            font-weight: 600;
          }
        }
        .es-sg-nmfc {
          grid-area: sg_nmfc; // 🗺
          font-weight: 400;
          font-size: 18px;
          span {
            font-size: 12px;
            color: #666;
            font-weight: 600;
          }
        }
        .es-sg-hazardous {
          grid-area: sg_hazardous; // 🗺
          justify-self: end;
          align-self: end;
          padding-right: 20px;
          color: #2b363b;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .wrapper-row-3 {
      grid-area: g_row_3; // 🗺
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      .es-sg-row-3 {
        // styles
        margin-top: 5px;
        letter-spacing: .3px;
        font-weight: 600;
        color: #666;
        // grid
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr 0.5fr 0.5fr;
        grid-template-areas:
        "sg_handling_units sg_handling_units sg_dimension_units sg_dimension_units sg_dimension_units sg_pieces sg_pieces sg_total_weight";
        .es-sg-handling-units-title {
          grid-area: sg_handling_units; // 🗺
        }
        .es-sg-dimension-units-title {
          grid-area: sg_dimension_units; // 🗺
        }
        .es-sg-pieces-title {
          grid-area: sg_pieces; // 🗺
        }
        .es-sg-total-weight-title {
          grid-area: sg_total_weight; // 🗺
        }
      }
    }
    .wrapper-row-4 {
      grid-area: g_row_4; // 🗺
      padding: 10px;
      .es-sg-row-4 {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr 0.5fr 0.5fr;
        grid-template-areas:
        "sg_input_qty sg_input_type sg_input_length sg_input_width sg_input_height sg_input_qty_2 sg_input_type_2 sg_input_total_weight";
        .es-sg-input-qty {
          grid-area: sg_input_qty; // 🗺
        }
        .es-sg-input-type {
          grid-area: sg_input_type; // 🗺
        }
        .es-sg-input-length {
          grid-area: sg_input_length; // 🗺
        }
        .es-sg-input-width {
          grid-area: sg_input_width; // 🗺
        }
        .es-sg-input-height {
          grid-area: sg_input_height; // 🗺
        }
        .es-sg-input-qty-2 {
          grid-area: sg_input_qty_2; // 🗺
        }
        .es-sg-input-type-2 {
          grid-area: sg_input_type_2; // 🗺
        }
        .es-sg-input-total-weight {
          grid-area: sg_input_total_weight; // 🗺
        }
      }
    }
    .wrapper-row-5 {
      grid-area: g_row_5; // 🗺
      .es-sg-row-5 {
        display: grid;
        margin-bottom: 10px;
        grid-template-rows: 1fr;
        grid-template-columns: 0.2fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr 0.5fr 0.5fr;
        grid-template-areas:
        ". . . . . . . sg_input_set_as_default";
        .es-sg-input-set-as-default {
          grid-area: sg_input_set_as_default; // 🗺
          font-weight: 600;
        }
      }
    }
    
  }
}

// Specific Component Level Form Styling
.es-c-container--add-items-to-shipment {
  .es-sg-input-type-1 .form-group.es-i-freight-class {
    label {
      color: #666;
      letter-spacing: .3px;
      font-size: 12px;
    }
  }
}

.es-c-container--add-items-to-shipment {
  .es-sg-input-type-2 .form-group.es-i-freight-class {
    label {
      color: #666;
      letter-spacing: .3px;
      font-size: 12px;
    }
  }
}

.es-c-container--add-items-to-shipment {
  .es-sg-input-qty .form-group.es-i-unit-qty {
    label {
      color: #666;
      letter-spacing: .3px;
      font-size: 12px;
    }
  }
}

.es-c-container--add-items-to-shipment {
  .es-sg-input-qty-2 .form-group.es-i-unit-qty-2 {
    label {
      color: #666;
      letter-spacing: .3px;
      font-size: 12px;
    }
  }
}
.es-c-btn.btn-edit {
    color: #666;
    font-weight: 600;
    background-color: transparent;
    border: transparent;
}
.es-c-btn.btn-remove {
    color: #666;
    font-weight: 600;
    background-color: transparent;
    border: transparent;
}


.form-input.input-lg.input-qty {
  width: 90%;
}
.form-select.select-lg.select-type {
  width: 90%;
}
.form-select.select-lg.select-type {
  width: 90%;
}
.form-input.input-lg.input-length {
  width: 50%;
}
.form-input.input-lg.input-width {
  width: 50%;
}
.form-input.input-lg.input-height {
  width: 50%;
}
.form-label.label-lg.label-length {
  font-size: 12px;
}
.form-label.label-lg.label-width {
  font-size: 12px;
}
.form-label.label-lg.label-height {
  font-size: 12px;
}

.form-input.input-lg.input-weight {
width: 90%;
}
.form-input.input-lg.input-weight::placeholder {
  opacity: 1;
  color: black;
  padding-left: 105px;
}
`;

// 🕳
const EchoShipFunctionalComponent = () => {

  return (
    <FunctionalComponentShipmentItems>
      <div className="es-c-container--add-items-to-shipment">
      <div className="wrapper-add-items-to-shipment">
        <div className="wrapper-row-1">
          <div className="es-sg-row-1">
            <div className="es-sg-subtitle">Plastic Bags</div>
            <div className="es-sg-edit">
           
              <button className="es-c-btn btn-edit">Edit</button>
           
              <span
                style={{
                  color: "#666"
                }}
              >
                |
              </span>
            
              <button className="es-c-btn btn-remove">Remove</button>
            
            </div>
          </div>
        </div>
        <div className="wrapper-row-2">
          <div className="es-sg-row-2">
            <div className="es-sg-freight-class">
              200 <br />
              <span>freight class</span>
            </div>
            <div className="es-sg-nmfc">
              No Data <br />
              <span>NMFC</span>
            </div>
            <div className="es-sg-hazardous">
            <i class="fas fa-exclamation-triangle"></i> Hazardous
            </div>
          </div>
        </div>
        <div className="wrapper-row-3">
          <div className="es-sg-row-3">
            <div className="es-sg-handling-units-title">Handling Units</div>
            <div className="es-sg-dimension-units-title">
              Handling Unit Dimensions (in)
            </div>
            <div className="es-sg-pieces-title">Pieces</div>
            <div className="es-sg-total-weight-title">Total Weight <span>*</span></div>
          </div>
        </div>
        <div className="wrapper-row-4">
          <div className="es-sg-row-4">
            <div className="es-sg-input-qty">
          
              <div className="form-group es-i-unit-qty">
                <input
                  className="form-input input-lg input-qty"
                  type="text"
                  id="input-unit-qty"
                />
                <label
                  className="form-label label-lg"
                  htmlFor="input-unit-qty"
                >
                  Qty
                </label>
              </div>
         
            </div>
            <div className="es-sg-input-type-1">
           
              <div className="form-group es-i-freight-class">
                <select className="form-select select-lg select-type">
                  <option>Rolls</option>
                  <option>Skids</option>
                  <option>Pallets</option>
                  <option>Floor</option>
                  <option>Bales</option>
                  <option>Gaylords</option>
                  <option>Corrugated</option>
                  <option>Crates</option>
                  <option>Drums</option>
                </select>
                <label className="form-label" htmlFor="input-type-1">
                  Type
                </label>
              </div>
           
            </div>
            <div className="es-sg-input-length">
          
              <div className="form-group es-i-unit-length">
                <input
                  className="form-input input-lg input-length"
                  type="text"
                  id="input-unit-length"
                />
                <label
                  className="form-label label-lg label-length"
                  htmlFor="input-unit-length"
                >
                  Length
                </label>
              </div>
            
            </div>
            <div className="es-sg-input-width">
             
              <div className="form-group es-i-unit-width">
                <input
                  className="form-input input-lg input-width"
                  type="text"
                  id="input-unit-width"
                />
                <label
                  className="form-label label-lg label-width"
                  htmlFor="input-unit-width"
                >
                  Width
                </label>
              </div>
            
            </div>
            <div className="es-sg-input-height">
             
              <div className="form-group es-i-unit-height">
                <input
                  className="form-input input-lg input-height"
                  type="text"
                  id="input-unit-height"
                />
                <label
                  className="form-label label-lg label-height"
                  htmlFor="input-unit-height"
                >
                  Height
                </label>
              </div>
            
            </div>
            <div className="es-sg-input-qty-2">
            
              <div className="form-group es-i-unit-qty-2">
                <input
                  className="form-input input-lg input-qty"
                  type="text"
                  id="input-unit-qty-2"
                />
                <label
                  className="form-label label-lg"
                  htmlFor="input-unit-qty-2"
                >
                  Qty
                </label>
              </div>
           
            </div>
            <div className="es-sg-input-type-2">
          
              <div className="form-group es-i-freight-class">
                <select className="form-select select-lg select-type">
                  <option>Select One</option>
                  <option>Bag</option>
                  <option>Boxes</option>
                  <option>Cans</option>
                  <option>Cartons</option>
                  <option>Case</option>
                  <option>Crate</option>
                  <option>Drums</option>
                  <option>EA</option>
                  <option>Fiber Drums</option>
                  <option>Flat Rack</option>
                  <option>Kegs</option>
                  <option>Other</option>
                  <option>Pails</option>
                  <option>Reels</option>
                  <option>Rolls</option>
                  <option>Tote</option>
                  <option>Tubes</option>
                  <option>Bundles</option>
                  <option>Pallets</option>
                  <option>Pieces</option>
                  <option>Cylinders</option>
                  <option>Tanks</option>
                </select>
                <label className="form-label" htmlFor="input-type-2">
                  Type
                </label>
              </div>
            
            </div>
            <div className="es-sg-input-total-weight">
            <div className="form-group form-weight">
                <input
                  className="form-input input-lg input-weight"
                  type="text"
                  id="input-unit-qty-2"
                  placeholder="LBS"
                />
              </div>
            </div>

          </div>

        </div>
        <div className="wrapper-row-5">
          <div className="es-sg-row-5">
            <div className="es-sg-input-set-as-default">
              <label>
                <input type="checkbox" /> Set as a default item
              </label>
            </div>
          </div>
        </div>
      </div>
      </div>
    </FunctionalComponentShipmentItems>
  )
}

export default EchoShipFunctionalComponent;