import React from "react"
import { Modal } from "semantic-ui-react"
import "../../styles/modal.css"
import "../../styles/icons/css/icons.css"


import styled from "styled-components"
import ShowHazmat from '../HideShow/HazardousMaterial/ShowHazmat.js'
import ModalButtonAddItem from "../../components/Buttons/ModalButtons/ModalButtonAddItem.js"

// 💅
const ModalAddItem = styled.div`
  /* border-radius: 4px;
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  max-width: 960px;
  // min-height: 800px !important;

  .form-select.select-lg.select-freight {
    width: 100%;
  }
  .row-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    .form-input.input-lg.input-weight {
      width: 80%;
    }
    .form-select.select-lg.select-freight {
      width: 80%;
    }
    .form-input.input-lg.input-nmfc {
      width: 80%;
    }
    .form-select.select-lg.select-sub {
      width: 80%;
    }   
  }
  .row-4 {
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    grid-template-rows: 1fr;
    .form-input.input-lg.input-unit-qty {
      width: 80%;
    }
    .form-select.select-lg.select-handling-type {
      width: 100%;
    }
  }
  .row-5 {
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    grid-template-rows: 1fr;
    .form-input.input-lg.input-piece-qty {
      width: 80%;
    }
    .form-select.select-lg.select-piece-type {
      width: 100%;
    }
  }
  .form-label.label-lg {
    margin-bottom: none;
  }
  .form-label {
    margin-bottom: .4rem;
  }
`

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: grid;
  grid-template-rows: minmax(0.2fr, 1fr)0.2fr 1fr 0.2fr;
  grid-template-columns: 0.3fr 0.7fr;
  grid-template-areas:
    "g_modal_header g_modal_header"
    "g_modal_content_left g_modal_content_right"
    "g_modal_footer g_modal_footer";
`

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 2px #e7edf0;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
  }
`

// MODAL GRID: CONTENT LEFT
const ModalGridContentLeft = styled.div`
  grid-area: g_modal_content_left; // 🗺
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;

  .Title {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
  }
  .SubText {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #141b1f;
    padding-top: 20px;
    line-height: 1.42857143;
  }
`

// MODAL GRID: CONTENT RIGHT
const ModalGridContentRight = styled.div`
  grid-area: g_modal_content_right; // 🗺
  border-left: solid 2px #e7edf0;
  padding: 15px;
`

// MODAL GRID: FOOTER
const ModalFooter = styled.div`
  grid-area: g_modal_footer; // 🗺
  .footer-row {
    display: grid;
    grid-template-columns: 1fr 1fr 0.8fr;
    grid-template-rows: 1fr;
    grid-template-areas:
    ". sg_save_future_shipments sg_btn_add_to_shipment";
  }
  .save-future-shipments {
    grid-area: sg_save_future_shipments;
    align-self: end;
    justify-self: start;
    padding-bottom: 20px;
    padding-left: 5px;
    padding-top: 12px;
    padding-right: 15px;
    margin-top: 10px;
    border-right: 0.1rem solid #bbb;
    font-family: "Open Sans";
    font-weight: 600;
    color: #666;
  }
`

const ButtonAddToShipment = styled.button`
  grid-area: sg_btn_add_to_shipment;
  justify-self: end;
  align-self: end;
  font-family: 'Open Sans';
  background-color: #26c1fb;
  border: 0.1rem solid #26c1fb;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px !important;
  display: inline-block;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 0.5rem;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  width: 80%;
  margin-right: 15px;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    opacity: 0.9;
    color: #fff;
    outline: 0;
    background-color: #04abea;
    border: 0.1rem solid #04abea;
  }
`;

const ModalModalAddItem = props => (
  <Modal
    trigger={<ModalButtonAddItem addItem>ADD A NEW ITEM</ModalButtonAddItem>}
  >
    <ModalAddItem>
      <ModalGrid>
        <ModalHeader>
          <div className="ModalTitle">SHIP A NEW ITEM</div>
          <div className="Exit" />
        </ModalHeader>

        <ModalGridContentLeft>
          <div className="Title">Expert Tip</div>
          <div className="SubText">
            Be as accurate as you can when adding new item details to avoid
            costly re-classes and reweighs.
          </div>
          <div className="SubText">
            If you are entering multiple items on one pallet, only fill out the
            pallet qty for the first item.
          </div>
        </ModalGridContentLeft>

        <ModalGridContentRight>
          {/* Description */}
          <div className="form-group es-i-min-cargo-liability">
            <label className="form-label label-lg">
              Description <span>*</span>
            </label>
            <input className="form-input input-lg" type="text" placeholder="" />
          </div>

            {/* Freight Class */}

            {/* Hazardous Material */}
            <ShowHazmat></ShowHazmat>

          {/* Row Two End*/}

          {/* Weight */}
          <div className="row-3">
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg input-weight">
                Weight <span>*</span>
              </label>
              <input
                className="form-input input-lg input-weight"
                type="text"
                placeholder=""
              />
            </div>
            {/* Unit */}
            <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Unit
              </label>
              <select className="form-select select-lg select-freight">
                <option>LBS</option>
                <option>KG</option>
              </select>
            </div>
            {/* NMFC */}
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                NMFC<span>*</span>
              </label>
              <input
                className="form-input input-lg input-nmfc"
                type="text"
                placeholder=""
              />
            </div>
            {/* Sub */}
            <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Sub
              </label>
              <select className="form-select select-lg select-sub">
                <option>-</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </select>
            </div>
          </div>

          <div className="row-4">
            {/* Unit Qty */}
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                Unit Qty<span>*</span>
              </label>
              <input
                className="form-input input-lg input-unit-qty"
                type="text"
                placeholder=""
              />
            </div>
            {/* Handling Unit Type */}
            <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Handling Unit Type
              </label>
              <select className="form-select select-lg select-handling-type">
                <option>Select One</option>
                <option>Bag</option>
                <option>Boxes</option>
                <option>Cans</option>
                <option>Cartons</option>
                <option>Case</option>
                <option>Crate</option>
                <option>Drums</option>
                <option>EA</option>
                <option>Fiber Drums</option>
                <option>Flat Rack</option>
                <option>Kegs</option>
                <option>Other</option>
                <option>Pails</option>
                <option>Reels</option>
                <option>Rolls</option>
                <option>Tote</option>
                <option>Tubes</option>
                <option>Bundles</option>
                <option>Pallets</option>
                <option>Pieces</option>
                <option>Cylinders</option>
                <option>Tanks</option>
              </select>
            </div>
          </div>

          <div className="row-5">
            {/* Unit Qty */}
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                Piece Qty<span>*</span>
              </label>
              <input
                className="form-input input-lg input-piece-qty"
                type="text"
                placeholder=""
              />
            </div>
            {/* Handling Unit Type */}
            <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Piece Type
              </label>
              <select className="form-select select-lg select-piece-type">
                <option>Select One</option>
                <option>Rolls</option>
                <option>Skids</option>
                <option>Pallets</option>
                <option>Floor</option>
                <option>Bales</option>
                <option>Gaylords</option>
                <option>Corrugated</option>
                <option>Crates</option>
                <option>Drums</option>
              </select>
            </div>
          </div>
        </ModalGridContentRight>

        <ModalFooter>
        <div className="footer-row">

            {/* Hazardous Material */}
            <label className="save-future-shipments">
              <input type="checkbox" /> Save item for future shipments
            </label>
            <ButtonAddToShipment>ADD TO SHIPMENT</ButtonAddToShipment>
            
        </div>
        </ModalFooter>
      </ModalGrid>
    </ModalAddItem>
  </Modal>
)
export default ModalModalAddItem
