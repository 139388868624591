import React from "react"
import styled from "styled-components"

const OrganismAddItemSummary = styled.div`
  body {
    @import url("https://fonts.googleapis.com/css?family=Teko");
    font-family: "Teko", sans-serif;
  }
  font-family: "Open Sans";
  color: #0033a1;
  margin-top: 20px;
  // 📰
  display: grid;
  grid-template-rows: 50px 10px 90px 80px 25px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "sg_title sg_title"
    "sg_underline_seperator_top sg_underline_seperator_top"
    "sg_origin sg_origin_extra"
    "sg_destination sg_destination_extra"
    "sg_underline_seperator_bottom sg_underline_seperator_bottom";
`
const SgUnderlineSeperatorTop = styled.div`
  grid-area: sg_underline_seperator_top; // 🗺
  /* margin-top: 30px;
    margin-bottom: 20px; */
  padding-top: 25px;
  border: 0;
  border-top: 1px solid #e7edf0;
`

const SgTitle = styled.p`
  grid-area: sg_title; // 🗺
  font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: 1.5;
  font-size: 30px;
  color: #141b1f;
`

const SgOrigin = styled.p`
  grid-area: sg_origin; // 🗺
  align-self: end;
  font-family: "Open Sans";
  color: #2b363b;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-bottom: 10px;
  span {
    color: #666;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    }
`

const SgOriginExtra = styled.p`
  grid-area: sg_origin_extra; // 🗺
  align-self: end;
  font-family: "Open Sans";
  color: #2b363b;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  padding-bottom: 10px;
`

const SgDestination = styled.p`
  grid-area: sg_destination; // 🗺
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  color: #141b1f;
  margin-bottom: 0;
  span {
    color: #666;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    }
`
const SgDestinationExtra = styled.p`
  grid-area: sg_destination_extra; // 🗺
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 400;
  color: #141b1f;
  span {
    color: #fff;
  }
`

const SgUnderlineSeperatorBottom = styled.div`
  grid-area: sg_underline_seperator_bottom; // 🗺
  padding-top: 25px;
  border: 0;
  border-top: 1px solid #e7edf0;
`

const EchoShipGroupComponent = () => {
  return (
    <OrganismAddItemSummary>
      <SgTitle>Summary</SgTitle>
      <SgUnderlineSeperatorTop />

      <SgOrigin>
        <span>Origin</span>
        <br />
        CHICAGO, IL <br />
        60610 US
      </SgOrigin>
      <SgOriginExtra>
        Wed, Mar 13
        <br />
        10:30 AM - 5:00 PM
      </SgOriginExtra>
      <SgDestination>
        <span>Destination</span>
        <br />
        Long Beach, CA <br />
        90802 US
      </SgDestination>
      <SgDestinationExtra>
        <span>Destination</span>
        <br />
        Tue, Apr 30
      </SgDestinationExtra>
      <SgUnderlineSeperatorBottom />
    </OrganismAddItemSummary>
  )
}

export default EchoShipGroupComponent
