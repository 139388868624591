import React from "react";
import ToggleHazmat from "./ToggleHazmat.js";
import '../../../styles/spectre.css';
import HazmatItems from '../HazardousMaterial/HazmatItems.js'
import styled from "styled-components"


const HazmatComponent = styled.div`
.row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.hazardous-material {
  align-self: end;
  padding-bottom: 20px;
  padding-left: 20px;
}
`
;

const ShowBlindOrigin = () => {
  return (
    <div>

        <ToggleHazmat>
          {({ show, toggleShow }) => (
            <div>
            <HazmatComponent>
    <div className="row-2">
    <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Type
              </label>
              <select className="form-select select-lg select-freight">
                <option>-</option>
                <option>50</option>
                <option>55</option>
                <option>60</option>
                <option>65</option>
                <option>70</option>
                <option>77.5</option>
                <option>85</option>
                <option>92.5</option>
                <option>100</option>
                <option>110</option>
                <option>125</option>
                <option>150</option>
                <option>175</option>
                <option>200</option>
                <option>250</option>
                <option>300</option>
                <option>400</option>
                <option>500</option>
              </select>
            </div>
            <label className="hazardous-material">
              <input type="checkbox" onClick={toggleShow} />
              <i class="fas fa-exclamation-triangle" /> Hazardous Material
            </label>
            </div>
    </HazmatComponent>
            <HazmatComponent></HazmatComponent>
              {show && <HazmatItems />}

            </div>

          )}
        </ToggleHazmat>

    </div>
  );
};

export default ShowBlindOrigin;