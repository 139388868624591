import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import FunctionalComponentShipmentItems from '../../../FunctionalComponents/FunctionalComponentShipmentItems.js'
import OrganismSearchOrAddItem from '../../../Organisms/OrganismSearchOrAddItem.js'
import OrganismAddItemDetails from '../../../Organisms/OrganismAddItemDetails.js'
import NavigationAddItems from '../../../PageComponents/Navigation/Quote/NavigationAddItems.js'
import OrganismAddItemSummary from '../../../Organisms/OrganismAddItemSummary.js'

import AddReferenceNumber from '../../../FunctionalComponents/Add/ReferenceNumber/ReferenceNumberModule.js'

const GContentAddItems = styled.div`
  body {
    @import url('https://fonts.googleapis.com/css?family=Teko');
    font-family: 'Teko', sans-serif;
  }
  margin: 0 auto;
  max-width: 1360px;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 1fr) 1fr 0.1fr;
  grid-template-columns: 1fr 0.35fr;
  grid-template-areas:
    "sg_add_items sg_summary"
    "sg_navigation_stepper sg_summary";

  .SGAddToShipment {
    grid-area: sg_add_items; // 🗺
  }
  .SGSummary {
    grid-area: sg_summary; // 🗺
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
    align-self: start;
  }
`;

const SGAddToShipment = styled.div`
    padding-right: 10px;
    border-right: 2px solid #f8f8f8;
    margin-top: 20px;
`;
const SGSummary = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
`;
const SGNavigationStepper = styled.div`
`;
const GComponentPageTitle = styled.div`
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;  
`;
const PageTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    margin: 0 auto;
    max-width: 1360px;
`;

const SGReferenceNumber = styled.div`
  // 📰
  display: grid;
  grid-template-rows: minmax(0.1fr, 1fr) 1fr 0.1fr;
  /* grid-template-columns: 1fr 0.35fr;
  grid-template-areas:
  "sg_add_items sg_summary"; */
`;
const ReferenceNumberTitle = styled.div`
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    max-width: 1360px;
`;


const GridComponentAddItems = ({children}) => {
  return (

    <GContentAddItems>

        <SGAddToShipment>

          <GComponentPageTitle>
            <PageTitle>Add Items to Shipment</PageTitle>
          </GComponentPageTitle>
          
          <FunctionalComponentShipmentItems></FunctionalComponentShipmentItems>
          <OrganismSearchOrAddItem></OrganismSearchOrAddItem>
          <OrganismAddItemDetails></OrganismAddItemDetails>

          <SGReferenceNumber>
            <ReferenceNumberTitle>Reference Numbers</ReferenceNumberTitle>

            <AddReferenceNumber>
              {children}
            </AddReferenceNumber>

          </SGReferenceNumber>

        </SGAddToShipment>

        <SGSummary>
          <OrganismAddItemSummary></OrganismAddItemSummary>
        </SGSummary>

        <SGNavigationStepper>
          <NavigationAddItems></NavigationAddItems>
        </SGNavigationStepper>



    </GContentAddItems>

  )
}

export default GridComponentAddItems;