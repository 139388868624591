import React from 'react'
import styled from 'styled-components'

// Import Required Components

import TypeAheadSavedItem from '../FunctionalComponents/Typeahead/SavedItem/TypeAheadSavedItem.js'

import ModalAddItem from '../FunctionalComponents/Modal/ModalAddItem.js'

const OrganismSearchOrAddItem = styled.div`

.es-sg-saved-item {
  .wrapper {
  margin-top: 30px;
  padding-bottom: 32px;
  /* border-bottom: 1px solid #bbbbbb; */
  max-width: 995px;
  // 📰
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 500px 0.1fr 0.2fr;
  grid-template-areas:
    "sg_col_1 sg_col_2 sg_col_3";
    
    .sg-col-1 {
      grid-area: sg_col_1; // 🗺
      align-self: center;
      padding-right: 30px;
    }
    .sg-col-2 {
      grid-area: sg_col_2; // 🗺
      justify-self: start;
      align-self: center;
    }
    .sg-col-3 {
      grid-area: sg_col_3; // 🗺
      justify-self: end;
      align-self: center;
      margin-left: 15px;
    }
}
  }

  hr {
    border-top: 1px solid #e7edf0;
  }
`

const EchoShipOrganism = ({children}) => {
  return (

    <OrganismSearchOrAddItem>
      <div className="es-sg-saved-item">
        <div className="wrapper">
          <div className="sg-col-1">
            {/* <div className="form-group es-i-total-shipment-value"         
            style={{
              width: "100%"
            }}>
              <input
                className="form-input input-lg"
                type="text"
                id="input-total-shipment-value"
                placeholder="Search for a saved item..."
              />
            </div> */}
            <TypeAheadSavedItem placeholder="Search for a saved item..."></TypeAheadSavedItem>
          </div>
          <div className="sg-col-2">OR</div>
          <div className="sg-col-3">
          <ModalAddItem>
            {children}
          </ModalAddItem>
          {/* <ModalButtonAddItem addItem>Add A New Item</ModalButtonAddItem> */}
          </div>
        </div>
      </div>
      <hr />
    </OrganismSearchOrAddItem>
  
    
  )
}

export default EchoShipOrganism;