import React from 'react'
import styled from 'styled-components'

const ReferenceNumberComponent = styled.div`
  // 📰
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "g_left g_right";

    .GridLeft {
      grid-area: g_left;
      .form-select.select-lg.select-reference {
        height: 30px;
        border-radius: 3px;
        font-size: 10px !important;
      }
    }
    .GridRight {
      grid-area: g_right;
      .form-input.input-lg {
        height: 30px;
        border-radius: 3px;
      }
    }

    .GridLeftBox {
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 3px;
      background-color: #e7edf0;
    }
    .GridRightBox {
      padding: 5px;
      padding-left: 20px;
      background-color: #f7f7f7;
      padding-right: 45px;
    }
`;

const GridComponentAddItems = () => {
  return (
    <ReferenceNumberComponent>
      <div className="GridLeftBox">
        <div className="GridLeft">
          <div className="form-group">
            <select className="form-select select-lg select-reference">
              <option>Accessorial Charge</option>
              <option>Account Number</option>
              <option>Alternate Carrier</option>
              <option>Batch Number</option>
              <option>Business Unit</option>
              <option>Carrier Name</option>
              <option>Carrier Pickup Number</option>
              <option>Client Code</option>
              <option>Client Name</option>
              <option>Cost Center</option>
              <option>Credit Card Reference Number</option>
              <option>Credit Card Transaction Key</option>
              <option>Fuel Surcharge</option>
              <option>GL Code</option>
              <option>Job/Promo Code</option>
              <option>Line Hual</option>
              <option>Load BOL #</option>
              <option>Load PO #</option>
              <option>Master BOL</option>
              <option>Order #</option>
              <option>Order Number</option>
              <option>Pickup #</option>
              <option>Pool Point</option>
              <option>Previous Billed</option>
              <option>Pro #</option>
              <option>Process Level</option>
              <option>Proposed Carrier</option>
              <option>Service Level</option>
              <option>Test Drop Down</option>
              <option>Zone</option>
              <option>Trailer Number</option>
            </select>
          </div>
        </div>
      </div>

      <div className="GridRightBox">
        <div className="GridRight">
          <div className="form-group">
            <input
              className="form-input input-lg"
              type="text"
              id="refNumber"
              placeholder="Enter Reference Number"
            />
          </div>
        </div>
      </div>


    </ReferenceNumberComponent>
  )
}

export default GridComponentAddItems;