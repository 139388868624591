import React from "react"
import styled from "styled-components"
import "../../../styles/spectre.css"
import hazmatItems from "./hazmatitems.png"


const HazmatItems = styled.div`
padding-left: none;
padding-right: none;
img {
  max-width: 100%;
  height: auto;
  background-size: cover;
}

`
;
const EchoHazmatItems = () => {
  return (
    
    <HazmatItems>
      <img src={hazmatItems}></img>
    </HazmatItems>
  )
}

export default EchoHazmatItems

