import _ from "lodash"
import React, { Component } from "react"
import styled from "styled-components"
import "../../../styles/icons/css/icons.css"
import { Button, Image, List, Transition } from "semantic-ui-react"

import ReferenceNumberComponent from "./ReferenceNumberComponent.js"

const AddButton = styled.button`
  font-family: "Open Sans";
  background-color: transparent;
  border: transparent;
  margin-left: 745px;
  border-radius: 3px;
  color: #0033a1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  float: right !important;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    color: #fff;
    outline: 0;
    background-color: #26c1fb;
    border-color: #26c1fb;
    text-decoration: none;
  }
`
const ButtonRemove = styled.button`
  /* Clear default button styles */
  background-color: transparent;
  color: transparent;
  border: transparent;
  border-radius: 3px;
  padding: 0px;
  color: #c10054;
  cursor: pointer;
  position: relative;
  left: -20px;
  top: -27px;
  z-index: 1;
  float: right;
  font-size: 16px;

  /* display: none; */

`;

const users = ["1", "2", "3", "4", "5", "6"]

export default class AddReferenceNumber extends Component {
  state = { items: users.slice(0, 0) }

  handleAdd = () =>
    this.setState({ items: users.slice(0, this.state.items.length + 1) })

  handleRemove = () => this.setState({ items: this.state.items.slice(0, -1) })

  render() {
    const { items } = this.state

    return (
      <div>
        <Button.Group>
          <AddButton
            disabled={items.length === users.length}
            onClick={this.handleAdd}
          >
            <i class="fas fa-plus" /> Add Reference Number
          </AddButton>
        </Button.Group>
        <br /> <br />


        <Transition.Group
          as={List}
          duration={200}
          verticalAlign="middle"
        >
          {items.map(item => (
            <List.Item key={item}>
              <ReferenceNumberComponent />
              <ButtonRemove
                disabled={items.length === 0}
                icon="minus"
                onClick={this.handleRemove}
              >
                <i class="fas fa-times" />
              </ButtonRemove>

              {/* <List.Content header={_.startCase(item)} /> */}
            </List.Item>
          ))}
        </Transition.Group>
      </div>
    )
  }
}
