import React from 'react'
import styled from 'styled-components'

// Import Required Components

const OrganismSearchOrAddItem = styled.div`
  .es-sg-picker {
    .picker-row-1,
    .picker-row-2,
    .picker-row-3,
    .picker-row-4,
    .picker-row-5,
    .picker-row-6,
    .picker-row-7 {
      display:flex;
      margin-top: 15px;
      margin-bottom: 40px;
    }
    .es-t-total-shipment-weight {
    margin-right: 280px;
    font-size: 14px;
    color: #666;
    font-weight: 600;
    p {
      font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-weight: 400;
      color: #2b363b;
      padding-top: 10px !important;
    }
    }
  }
  textarea {
    margin: 0px;
    width: 706px;
    height: 180px !important;
  }
  textarea::placeholder {
    font-size: 16px;
  }
  textarea {
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    padding: 4px 8px;
  }
  .form-group.es-i-total-skid-spots {
    width: 25%;
  }
`

const EchoShipOrganism = () => {
  return (

    <OrganismSearchOrAddItem>
      <div className="es-sg-picker">
        <div className="picker-row-1">
          <div className="es-t-total-shipment-weight">
            Total Shipment Weight <br />
            <p>0 LBS</p>
          </div>
          <div className="es-t-total-shipment-weight">
            Total Handling Units (Pallets) <br />
            <p>23</p>
          </div>
      
        </div>
        <div className="picker-row-2">
          <div className="form-group es-i-shipment-stackable">
            <label className="form-label">
              Are all the items in this shipment stackable?
            </label>
            <label>
              <input type="radio" name="stackable" defaultChecked /> Yes, all items are stackable<br />
              <input type="radio" name="stackable" /> No, not all items are stackable
            </label>

          </div>
        </div>
        <div className="picker-row-3">
        
          <div className="form-group es-i-total-skid-spots">
            <label
              className="form-label label-lg"
              htmlFor="input-total-skid-spots"
            >
              Total Skid Spots <span>*</span>
            </label>
            <p> 1 skid spot = 48" x 48" x 48"</p>
            <input
              className="form-input input-lg"
              type="number"
              id="input-total-skid-spots"
              onFocus={fillInput.bind(this, '1')}
              style={{
                width: "100%"
              }}
            />
          </div>
        
        </div>
        <div className="picker-row-4">
         
          <div className="form-group es-i-shipment-stackable">
            <label className="form-label">
              Do you know the value of your shipment?
            </label>
            <label>
              <input type="radio" name="value" onChange={showValueInput.bind(this)}/> Yes<br />
              <input type="radio" name="value" defaultChecked onChange={hideValueInput.bind(this)}/> No
            </label>
          </div>
       
        </div>

        <div id="shipmentValue" className="picker-row-3" style={{display: 'none'}}>
        
          <div className="form-group es-i-total-skid-spots">
            <label
              className="form-label label-lg"
              htmlFor="input-shipment-value"
            >
              Shipment Value <span>*</span>
            </label>
            <input
              className="form-input input-lg"
              type="number"
              id="input-shipment-value"
              onFocus={fillInput.bind(this, '10.000')}
            />
          </div>
        
        </div>
        {/* <div className="picker-row-5">
      
          <div className="form-group es-i-total-shipment-value">
            <label
              className="form-label label-lg"
              htmlFor="input-total-shipment-value"
            >
              Total Shipment Value <span>*</span>
            </label>
            <input
              className="form-input input-lg"
              type="number"
              id="input-total-shipment-value"
              style={{
                width: "32%"
              }}
            />
          </div>
  
        </div>
        <div className="picker-row-6">
        
          <div className="form-group es-i-shipment-stackable">
            <label className="form-label">Condition of Items</label>
            <label>
              <input type="radio" name="newused" /> Yes<br />
              <input type="radio" name="newused" defaultChecked /> No
            </label>
          </div>
      
        </div> */}


        <div className="picker-row-7">
         
          <div className="form-group es-i-special-instructions">
            <label className="form-label" htmlFor="input-special-instructions">
              Special Instructions
            </label>
            <textarea
              className="form-input"
              id="input-message"
              placeholder=""
              rows={3}
              defaultValue={""}
            />
          </div>
         
        </div>
      </div>
    </OrganismSearchOrAddItem>
  
    
  )
}

function fillInput( fillVal, event ){
  let targetObj = event.target;
  if( targetObj.value == '' ){
    targetObj.value = fillVal;
  }
}

function showValueInput(){
  document.getElementById('shipmentValue').style.display = 'block';
}

function hideValueInput(){
  document.getElementById('shipmentValue').style.display = 'none';
}

export default EchoShipOrganism;