import { useState } from "react";
import '../../../styles/spectre.css';

const ToggleHazmat = ({ children }) => {
  let [show, setShow] = useState(false);

  const toggleShow = () => setShow((show = !show));

  return children({ show, toggleShow });
};

export default ToggleHazmat;